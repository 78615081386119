<template lang="html">
  <div class="h-100">
    <section class="section container h-100">
      <div class="section__aside h-100">
        <div class="aside">
          <a class="title">
            Ke stažení
          </a>

          <a
            class="aside__item"
            @click.prevent="setActive('catalogs')"
            :class="{ active: isActive('catalogs') }"
            href="#"
          >
            Katalogy a ceníky
          </a>
          <a
            class="aside__item"
            @click.prevent="setActive('configmanual')"
            :class="{ active: isActive('configmanual') }"
            href="#"
          >
            Návod k používaní konfigurátoru
          </a>
        </div>
        <div class="content">
          <div v-if="isActive('catalogs')">
            <h1>Katalogy a ceníky</h1>

            <h2>Produktový katalog</h2>
            <a
              href="catalogs/solodoor_katalog.pdf"
              target="_blank"
              class="d-if fd-r ai-c"
            >
              <PdfSvg />
              <p class="ml-md">Produktový katalog</p>
            </a>

            <h2>Ceník</h2>
            <a
              href="catalogs/solodoor-cenik.pdf"
              target="_blank"
              class="d-if fd-r ai-c"
            >
              <PdfSvg />
              <p class="ml-md">Ceník</p>
            </a>
          </div>
          <div v-if="isActive('configmanual')">
            <h1>Návod k používání konfigurátoru</h1>

            <video width="100%" height="400" controls preload class="w-100">
              <source
                src="@/assets/videos/konfiginstrukce.mp4"
                type="video/mp4"
              />
              <source
                src="@/assets/videos/konfiginstrukce.ogg"
                type="video/ogg"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import PdfSvg from "@/assets/icons/pdf.svg";

export default {
  components: {
    PdfSvg,
  },
  data() {
    return {
      activeItem: "catalogs",
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
};
</script>
<style lang=""></style>
