<template>
  <div class="home" id="top">
    <section class="section"></section>

    <section class="section" id="o-spolecnosti">
      <div class="container home--info">
        <div class="header__text">
          <h1>Interiérové dveře Chrudim, Pardubice</h1>
          <ul class="list">
            <li>velká nabídka modelů a dekorů dveří</li>
            <li>velmi vysoká kvalita a vysoce profesionální přístup</li>
            <li>absolutní špička ve svém oboru</li>
            <li>kvalitní servis a profesionální poradenství</li>
            <li>tradice a zkušenosti</li>
          </ul>
          <div class="grid-columns-3 jc-fs mt-xl">
            <div class="d-f">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#6AAF50;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#4D8538;"
                  d="M135.693,102.206l-0.008,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939l159.073,159.073c39.82-19.335,73.863-48.69,98.876-84.783  l-58.697-58.697c-2.262-3.334-5.169-6.299-8.681-8.681L177.747,112.833C168.453,99.138,150.365,94.55,135.693,102.206z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M349.593,300.614c-8.192-5.559-18.954-5.531-27.116,0.071l-11.752,8.066  c-13.09,8.984-30.498,8.496-43.08-1.187c-11.858-9.127-23.176-18.913-33.924-29.283c-10.371-10.748-20.156-22.065-29.283-33.924  c-9.684-12.581-10.171-29.989-1.187-43.08l8.066-11.752c5.601-8.162,5.63-18.924,0.071-27.116l-33.64-49.575  c-9.293-13.694-27.381-18.282-42.054-10.627l-0.009,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939c35.226,31.674,75.449,57.921,119.423,77.468  c30.549,13.58,66.306,1.145,81.77-28.494l0.004-0.009c7.655-14.672,3.068-32.761-10.627-42.054L349.593,300.614z"
                />
              </svg>
            </div>
            <span class="text--bold">Mobil 1:</span>
            <a href="tel:+420604168932">+420 604 168 932</a>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#6AAF50;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#4D8538;"
                  d="M135.693,102.206l-0.008,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939l159.073,159.073c39.82-19.335,73.863-48.69,98.876-84.783  l-58.697-58.697c-2.262-3.334-5.169-6.299-8.681-8.681L177.747,112.833C168.453,99.138,150.365,94.55,135.693,102.206z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M349.593,300.614c-8.192-5.559-18.954-5.531-27.116,0.071l-11.752,8.066  c-13.09,8.984-30.498,8.496-43.08-1.187c-11.858-9.127-23.176-18.913-33.924-29.283c-10.371-10.748-20.156-22.065-29.283-33.924  c-9.684-12.581-10.171-29.989-1.187-43.08l8.066-11.752c5.601-8.162,5.63-18.924,0.071-27.116l-33.64-49.575  c-9.293-13.694-27.381-18.282-42.054-10.627l-0.009,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939c35.226,31.674,75.449,57.921,119.423,77.468  c30.549,13.58,66.306,1.145,81.77-28.494l0.004-0.009c7.655-14.672,3.068-32.761-10.627-42.054L349.593,300.614z"
                />
              </svg>
            </div>
            <span class="text--bold">Mobil 2:</span>
            <a href="tel:+420603358940">+420 603 358 940</a>
          </div>
          <div></div>
        </div>
        <div class="header__image">
          <img
            :src="require('@/assets/graphic/IMAG3274.jpg')"
            alt="header image"
            title="Dvere solodoor chrudim"
          />
        </div>
      </div>
    </section>

    <section class="section">
      <div class="d-f jc-c fw-w">
        <a
          class="box box--btn ma-md"
          href="https://www.sestavsidvere.cz/cs/"
          target="_blank"
        >
          <div class="box__img">
            <SvgConfig />
          </div>
          <span class="mt-lg">Konfigurátor dveří</span>
        </a>
        <a
          class="box box--btn ma-md"
          href="https://www.solodoor.cz/cs/vizualizace/"
          target="_blank"
        >
          <div class="box__img">
            <SvgVisual />
          </div>
          <span class="mt-lg">Vizualizace dveří</span>
        </a>
        <a
          class="box box--btn ma-md"
          href="catalogs/solodoor_katalog.pdf"
          target="_blank"
          download
        >
          <div class="box__img">
            <SvgCatalog />
          </div>
          <div class="d-f ai-c mt-lg">
            <span class="mr-md">Katalog</span>
            <SvgPdf />
          </div>
        </a>
        <a
          class="box box--btn ma-md"
          href="catalogs/solodoor-cenik.pdf"
          target="_blank"
          download
        >
          <div class="box__img">
            <SvgPrice />
          </div>
          <div class="d-f ai-c mt-lg">
            <span class="mr-md">Ceník</span>
            <SvgPdf />
          </div>
        </a>
      </div>
    </section>

    <section class="section" id="informace">
      <SecInfo />
    </section>

    <section class="section" id="katalog-a-cenik">
      <SecCatalog />
    </section>
    <section class="section" id="prodejci">
      <SecSales />
    </section>
  </div>
</template>

<script>
import SecCatalog from "@/views/catalog.vue";
import SecInfo from "@/views/info.vue";
import SecSales from "@/views/sales.vue";

import SvgPdf from "@/assets/icons/pdf.svg";
import SvgConfig from "@/assets/icons/config.svg";
import SvgCatalog from "@/assets/icons/catalog.svg";
import SvgPrice from "@/assets/icons/cenik.svg";
import SvgVisual from "@/assets/icons/visual.svg";

export default {
  name: "Home",
  components: {
    SvgPdf,
    SvgConfig,
    SvgPrice,
    SvgCatalog,
    SvgVisual,
    SecInfo,
    SecCatalog,
    SecSales,
  },
};
</script>

<style lang="scss">
@import "@/resources/styles/Base/Mixins/_responsive.scss";

.header__image {
  width: 100%;

  img {
    width: 100%;
  }
}

.home--info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gap-md);
}

@include res-max-mobile {
  .header__text {
    order: 2;
  }

  .home--info {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}

.list li {
  list-style: square;
  margin-left: var(--gap-lg);
}

.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;

  &__icon {
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
  }

  &__viewport {
    overflow: hidden;
  }

  &__track {
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
  }

  &__slide {
    scroll-snap-stop: auto;
    flex-shrink: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prev {
    left: var(--gap-md);
  }

  &__next {
    right: var(--gap-md);
  }

  &__pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    display: flex;
    justify-content: center;
    list-style: none;

    &-button {
      padding: 0;
      margin: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      cursor: pointer;
      color: var(--color-primary);
      border: 2px solid currentColor;
      background-color: transparent;

      &--active {
        background-color: currentColor;
      }
    }
  }
}

.carousel__prev,
.carousel__next {
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: calc(2rem * 2 / 3);
  padding: 0;
  color: var(--color-primary-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
}
</style>
